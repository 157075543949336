import { LoginButton, TelegramAuthData } from "@telegram-auth/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type Status = 'init' | 'loading' | 'success' | 'error'

const StatusComponent = ({ status }: { status: Status }) => {
    switch (status) {
        case 'loading':
            return <p>Loading...</p>
        case 'success':
            return <p>Redirecting...</p>
        case 'error':
            return <p style={{ color: "red" }}>There was an error processing your login. Please try again.</p>
        default:
            return null
    }
}

export function Telegram() {
    const [searchParams] = useSearchParams()
    const [status, setStatus] = useState<Status>('init')

    const code = searchParams.get('code')
    const state = searchParams.get('state')
    const botUsername = searchParams.get('bot_username')

    useEffect(() => {
        document.title = "Telegram Login";

        const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
        if (favicon) {
            favicon.href = "/assets/telegram.ico";
        }
    }, []);

    if (!botUsername || !code || !state) {
        return (
            <div className="center">
                <h1>Invalid URL</h1>
                {
                    !botUsername ? <span>Bot username is missing</span> : null
                }
                {
                    !code ? <span>Code is missing</span> : null
                }
                {
                    !state ? <span>State is missing</span> : null
                }
            </div>
        )
    }

    const onAuthCallback = (data: TelegramAuthData) => {
        console.log(data)
        setStatus('loading')
        const dataCheckString = Object.keys(data)
            .filter(key => key !== 'hash')
            .sort()
            .map(key => `${key}=${data[key as keyof TelegramAuthData]}`)
            .join('\n');

        const jsonData = {
            id: data.id.toString(),
            first_name: data.first_name,
            username: data.username || '',
            photo_url: data.photo_url || '',
            auth_date: data.auth_date.toString(),
            hash: data.hash,
            payload: dataCheckString,
            code: code,
            state: state
        };

        fetch('/api/telegram', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {
                if (data.redirect_url) {
                    setStatus('success')
                    window.location.href = data.redirect_url;
                } else {
                    setStatus('error')
                    console.error('Error: No redirect URL in response');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setStatus('error')
            });
    }


    return (
        <div className="center">
            <LoginButton
                botUsername={botUsername}
                onAuthCallback={onAuthCallback}
                buttonSize="large" // "large" | "medium" | "small"
                cornerRadius={10} // 0 - 20
                showAvatar={true} // true | false
                lang="en"
            />
            <StatusComponent status={status} />
        </div>
    )
}
